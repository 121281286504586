import React from 'react';
import './features.css';
import { Feature } from '../../components';

const featuresData = [
  {
    title: 'Improving end distrust instantly',
    text: 'testestestestestestestest'
  },
  {
    title: 'Become the tended active',
    text: 'testestestestestestestest'
  },
  {
    title: 'Message or am nothing',
    text: 'testestestestestestestest'
  },
  {
    title: 'Realy boy law county',
    text: 'testestestestestestestest'
  },
]


const Features = () => {
  return (
    <div className='gpt3__features section__padding' id='features'>
      <div className='gpt3__features-heading'>
        <h1 className='gradient__text'>
          The Future is now and You Just Need To Realize It. Step into the 
          Future Today & Make it Happen
        </h1>
        <p>Request Early Acces to Get Started</p>
      </div>
        <div className='gpt3__features-container'>
          {featuresData.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title + index} />
          ))}
        </div>
    </div>
  )
}

export default Features