import React from 'react';
import './footer.css';
import gpt3Logo from '../../assets/logo.svg'

const Footer = () => {
  return (
    <div className='gpt3__footer section__padding'>
      <div className='gpt3__footer-heading'>
        <h1 className='gradient__text'>Do you want to step into the future before others?</h1>
      </div>
      <div className='gpt3__footer-btn'>
        <p>Request Early Access</p>
      </div>

      <div className='gpt3__footer-links'>
        <div className='gpt3__footer-links_logo'>
          <img src={gpt3Logo} alt='Logo' />
          <p>Hollywood, Florida, United States</p>
        </div>
        <div className='gpt3__footer-links_div'>
          <h4>Links</h4>
          <p>Contact</p>
          <p>Social Media</p>
          <p>Help</p>
          <p>Overons</p>
        </div>
        <div className='gpt3__footer-links_div'>
          <h4>Company</h4>
          <p>Privacy</p>
          <p>Terms & Conditions</p>
          <p>Contact</p>
        </div>
        <div className='gpt3__footer-links_div'>
          <h4>Get in Touch</h4>
          <p>Hollywood</p>
          <p>Florida</p>
          <p>carlos@juarezlive.com</p>
        </div>
      </div>
      <div className='gpt3__footer-copyright'>
        <p>c 2021 GPT-3. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer